<template>
  <div
    class="article-card"
    itemscope
    itemtype="https://schema.org/NewsArticle"
  >
    <div class="article-card__image">
      <nuxt-img
        :src="String(article.imageId)"
        width="375"
        height="225"
        fit="cover"
        loading="lazy"
      />
    </div>
    <div class="article-card__title">{{ article.title }}</div>
    <div v-html="article.announce" class="article-card__anonce"/>
    <div class="article-card__button">
      <nuxt-link
        class="btn btn-primary"
        :to="localePath(article.url)"
      >{{$t('common.read_more')}}</nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IArticleItem } from '#sitis/internal/controllers/articles/models/ArticleItem';
import type { ComputedRef } from 'vue';

interface IProps {
  article: IArticleItem
}
const props = withDefaults(defineProps<IProps>(), {});
const localePath = useLocalePath();


</script>

<style scoped lang="scss">
.article-card {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background: hsla(var(--white)/1);
  border: 1px solid hsla(var(--black)/0.1);
  box-shadow: 0px 8px 16px 0px hsla(var(--black)/0.05);
  padding: 24px 32px;
  box-sizing: border-box;
}
.article-card__image {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  background: hsla(var(--white)/1);
  box-shadow: 0px 8px 16px 0px hsla(var(--black)/0.15);
  margin-top: -48px;

  img {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    object-fit: cover;
  }
  &:after {
    content: "";
    float: left;
    padding-top: 78.463%;
  }
}
.article-card__title {
  margin-top: 20px;

  font-size: 22px;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: hsla(var(--main-section-tx)/1);
}
.article-card__anonce {
  margin-top: 12px;
  margin-bottom: auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;

  font-size: 16px;
  line-height: 1.4;
  color: hsla(var(--link-tx)/1);
}
.article-card__button {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 16px;
}

@media (max-width: 859px) {
  .article-card {
    padding: 12px 14px;

  }
  .article-card__image {
    margin-top: -38px;
  }
}
</style>
